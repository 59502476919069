.stoneBG {
    background-image: url("../images/abstract-1850424_640.jpg");
    padding: 8px;
    border: 8px solid black;
    width: 300px;
    height: 290px;
}   

.MidLine {
    background-color: #FFF;
    width: 75%;
}